import logo from "./logo.svg";
import "./App.css";
import RegistrationForm from "./RegistrationForm";
import { Button, Grid } from "@mui/material";
import CourseBanner from "./CourseBanner";
import React, { useEffect, useRef, useState } from "react";
import Loader from "./Loader";

import { Link } from "react-router-dom";
const Home = () => {
  const [data, setData] = useState({name:"cpr"});

  useEffect(() => {
    const storedData = loadData();
    if (storedData) {
      setData(storedData);
    }
  }, []);
  const loadData = () => {
    const data = localStorage.getItem('myDataKey');

    if (data === null) {
      console.log('No data found for this key.');
    } else {
      
      console.log('Data found:', data);
      return data
    }
  };

  const startDivRef = useRef(null);
  const targetDivRef = useRef(null);
  const [loader, setLoader] = useState(true);
  const scrollToTarget = () => {
    if (targetDivRef.current) {
      targetDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Grid container spacing={2} style={{ height: "100vh" }}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          style={{ height: "100%" }}
          ref={startDivRef}
          onClick={scrollToTarget}
        >
          <CourseBanner />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          style={{
            background: `radial-gradient(circle, rgb(255, 255, 255) 11%, rgb(243 93 32) 86%)`,
            height: "100vh",
          }}
        >
          <div ref={targetDivRef} style={{ margin: "1rem", height: "100%" }}>
            <RegistrationForm />
          </div>
        </Grid>
      </Grid>
   
    </>
  );
};

export default Home;
