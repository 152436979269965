
import img from "./assets/TTT.png"
const CourseBanner=()=>{
return (
    
			<div  style={{height:"100%", width:"100%" }} >
            <img src={img} style={{width:"100%", height:"100%"}}/>
            
        </div>
)
}

export default CourseBanner