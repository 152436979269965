import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import React from "react";
import axios from "axios";
const Payment = () => {
  const location = useLocation();
  const formData = location.state;
  const formRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false);
  console.log(location.state);
  
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    if (isChecked) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [isChecked]);

  useEffect(() => {
    const currentForm = formRef.current;

    if (!currentForm) return; // Ensure the form reference exists

    // Check if the script is already loaded
    const existingScript = currentForm.querySelector("script");
    if (existingScript) return; // Prevent loading the script multiple times

    // Create a new script element
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/payment-button.js";
    script.async = true;
    script.setAttribute("data-payment_button_id", "pl_OCRShPjGfYl0CT");

    // Append the script to the form
    currentForm.appendChild(script);

    handleSaveData(formData);
  }, [isChecked]);

 
  
  const handleSaveData=()=>{

    const data = JSON.stringify(formData);
  
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://hidden-atoll-09446-84ebc53750bc.herokuapp.com/savedata',
      headers: { 
        'Content-Type': 'application/json'
      },
      data: data
    };
  
    axios.request(config)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }
  return (
    <>
      <div
        style={{
          height: "fit-content",
          overflowY: "auto",
          padding: "1rem",
          marginBottom: "10px",
          maxWidth: 700,
          margin: "auto",
        }}
      >
        <h1>Terms and Conditions</h1>
        <ol>
          <li>
            <strong>Liability for Injuries:</strong> The management assumes no
            responsibility for injuries sustained during the course. First aid
            will be available on site.
          </li>
          <li>
            <strong>Personal Belongings:</strong> Participants are solely
            responsible for the security of their personal belongings.
          </li>
          <li>
            <strong>Age Requirement:</strong> Participants must be 18 years of
            age or older to enroll in this course.
          </li>
          <li>
            <strong>Fees for Accommodation and Meals:</strong> Charges for
            accommodation and meals are not included in the course fee. Details
            will be provided to successful applicants.
          </li>
          <li>
            <strong>Bringing Dogs:</strong> Participants are encouraged to bring
            their own dogs for hands-on training sessions. Dogs must have
            up-to-date vaccination records as prescribed by Canaan Pet Resort.
            If it is not possible to bring your own dog, a dog may be provided
            by Team CPR, subject to availability.
          </li>
          <li>
            <strong>Accommodation Recommendations:</strong> For participants
            residing outside Bangalore, accommodations within 2 kilometers of
            the venue will be recommended.
          </li>
          <li>
            <strong>Dress Code:</strong> The dress code, which includes a K9
            Coach t-shirt, track pants, sports shoes, and cap, is mandatory. The
            Canaan K9 t-shirt will be provided by Canaan Pet Resort & K9
            Academy.
          </li>
          <li>
            <strong>Seat Availability:</strong> Admission to the course is
            limited and will be granted on a first-come, first-served basis.
          </li>
          <li>
            <strong>Conduct:</strong> Team CPR expects all participants to
            maintain discipline, exhibit flexibility in learning, and show
            respect towards fellow trainers, regardless of gender, caste, race,
            or creed.
          </li>
          <li>
            <strong>Boarding Charges:</strong> If a participant opts to board
            their dog at the facility, the charges will be Rs.450 for day
            boarding and Rs.750 for night boarding, including food.
          </li>
          <li>
            <strong>Emergency Medical Assistance:</strong> Medical assistance
            for emergencies will be available for both humans and dogs
            throughout the course.
          </li>
          <li>
            <strong>Use of Personal Information and Media:</strong> By
            participating in this course, you authorize Canaan Pet Resort & K9
            Academy to use your details and any photographs/videos taken before,
            during, or after the course for publicity and marketing purposes.
          </li>
          <li>
            <strong>Video Recording Policy:</strong> Recording videos is not
            permitted unless explicitly authorized by the management.
          </li>
        </ol>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "2rem",
        }}
      >
        <label style={{ fontSize: "18px", fontWeight: "700" }}>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          I Agree to the Terms and Conditions
        </label>
      </div>
      {isChecked && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "2rem",
            paddingBottom: "5rem",
          }}
        >
          <form ref={formRef}></form>
        </div>
      )}
    </>
  );
};

export default Payment;
