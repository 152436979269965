import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const BringDogRadioButton = (props) => {
  const handleChange = (e) => {
    props.setBringingDog(e.target.value);
  };
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="bring dog radio button"
        onChange={handleChange}
        name="radio-buttons-group"
      >
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
  );
};
export default BringDogRadioButton;
