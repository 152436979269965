import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const TShirtSizeRadioButton=(props)=> {
  const handleSize=(e)=>{
    props.setSize(e.target.value)
    console.log(e.target.value)
  }
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        onChange={handleSize}
      >
        <FormControlLabel value="s" control={<Radio />} label="S" />
        <FormControlLabel value="m" control={<Radio />} label="M" />
        <FormControlLabel value="l" control={<Radio />} label="L" />
        <FormControlLabel value="xl" control={<Radio />} label="XL" />
        <FormControlLabel value="xxl" control={<Radio />} label="XXL" />
        <FormControlLabel value="xxxl" control={<Radio />} label="XXXL" />

      </RadioGroup>
    </FormControl>
  );
}
export default TShirtSizeRadioButton