import logo from './logo.svg';
import './App.css';
import RegistrationForm from './RegistrationForm';
import { Button, Grid } from '@mui/material';
import CourseBanner from './CourseBanner';
import React, { useRef, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home'
import Application from './Application'
import Payment from './Payment'
function App() {

  return (
    <>
     <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/application" element={<Application />} />
      <Route path="/payment" element={<Payment />} />


    </Routes>
    </>


 );
}

export default App;
