import { Alert, Button, Grid, TextField, Typography } from "@mui/material";

import axios from "axios";
import SuccessModal from "./SuccessModal";
import FailureModal from "./FailureModal";
import Loader from "./Loader";
import emailImg from "./assets/Email.png";
import RadioButton from "./GendeRadioButton";
import ProfessionCheckBox from "./ProfessionCheckBox";
import PetIndustryCheckBox from "./PetIndustryCheckBox";
import TShirtSizeRadioButton from "./TShirtSizeRadioButton";
import BringDogRadioButton from "./BringDogRadioButton";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const RegistrationForm = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [city, setCity] = useState("");
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [loader, setLoader] = useState(false);
  const [emergencyNumber, setEmergencyNumber] = useState("");
  const [address, setAddress] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState();
  const [industry, setIndustry] = useState();
  const [profession, setProfession] = useState([]);
  const [businessName, setBusinessName] = useState("");
  const [experience, setExperience] = useState("");
  const [source, setSource] = useState("");
  const [vision, setVision] = useState("");
  const [size, setSize] = useState("");
  const [instagramHandle, setInstagramHandle] = useState("");
  const [medical, setMedical] = useState("");
  const [bringingDog, setBringingDog] = useState("");
  const [error, setError] = useState(false);

  const handleName = (e) => {
    setName(e.target.value);
    console.log(name);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleNumber = (e) => {
    setNumber(e.target.value);
  };
  const handleECNumber = (e) => {
    setEmergencyNumber(e.target.value);
  };
  const handleAddress = (e) => {
    setAddress(e.target.value);
  };
  const handleAge = (e) => {
    setAge(e.target.value);
  };
  const handleBusinessName = (e) => {
    setBusinessName(e.target.value);
  };
  const handleExperience = (e) => {
    setExperience(e.target.value);
  };
  const handleSource = (e) => {
    setSource(e.target.value);
  };
  const handleVision = (e) => {
    setVision(e.target.value);
  };
  const handleInstagramHandle = (e) => {
    setInstagramHandle(e.target.value);
  };
  const handleMedical = (e) => {
    setMedical(e.target.value);
  };
  const handleCity = (e) => {
    setCity(e.target.value);
  };
  let formData={name, email, number, emergencyNumber , address,age,gender,industry,profession,businessName,experience,source,size,instagramHandle,medical,bringingDog,city}

  const handleProceedPayment = () => {
    if (
      name &&
      email &&
      number &&
      emergencyNumber &&
      address &&
      age &&
      gender &&
      industry &&
      profession &&
      businessName &&
      experience &&
      source &&
      size &&
      instagramHandle &&
      medical &&
      bringingDog &&
      city
    ) {
      setError(false);
      navigate("/payment",{ state:  formData });
    } else {
 setError(true);
    }
    
  };


  return (
    <>
      {loader && <Loader />}
      {success && <SuccessModal />}
      {failure && <FailureModal />}
      <Grid
        container
        spacing={2}
        style={{
          background: `radial-gradient(circle, rgb(255, 255, 255) 11%, rgb(4, 62, 111) 86%)	`,
          width: "100%",
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <img
            src={emailImg}
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
            alt="img"
          />
        </Grid>
      </Grid>
      <div
        style={{
          padding: 16,
        }}
      >
        <Grid
          container
          spacing={1}
          style={{
            marginTop: 16,
            maxWidth: 1280,
            margin: "auto",
            width: "100%",
          }}
        >
          <Grid item xs={12} sm={12} style={{ paddingTop: 8 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 700, margin: "0.5rem 0rem" }}
            >
              Full Name*
            </Typography>
            <TextField
              required
              fullWidth
              id="full name"
              name="full name"
              autoComplete="full name"
              style={{ marginBottom: "1px white" }}
              value={name}
              onChange={handleName}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 8 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 700, margin: "0.5rem 0rem" }}
            >
              Email Id
            </Typography>
            <TextField
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              value={email}
              onChange={handleEmail}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 8 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 700, margin: "0.5rem 0rem" }}
            >
              Contact Number
            </Typography>
            <TextField
              required
              fullWidth
              name="contact number"
              type="number"
              id="number"
              autoComplete="number"
              value={number}
              onChange={handleNumber}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 8 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 700, margin: "0.5rem 0rem" }}
            >
              Emergency Contact Number
            </Typography>
            <TextField
              required
              fullWidth
              name="Emergency Contact number"
              type="number"
              id="Ec number"
              autoComplete="Ec number"
              value={emergencyNumber}
              onChange={handleECNumber}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 8 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 700, margin: "0.5rem 0rem" }}
            >
              Address
            </Typography>
            <TextField
              multiline
              rows={4}
              value={address}
              onChange={handleAddress}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 8 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 700, margin: "0.5rem 0rem" }}
            >
              Age
            </Typography>
            <TextField
              required
              fullWidth
              name="age"
              type="number"
              id="age"
              autoComplete="number"
              value={age}
              onChange={handleAge}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 8 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 700, margin: "0.5rem 0rem" }}
            >
              Gender
            </Typography>
            <RadioButton setGender={setGender} />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 8 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 700, margin: "0.5rem 0rem" }}
            >
              Are you into Pet Industry?
            </Typography>
            <PetIndustryCheckBox setIndustry={setIndustry} />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 8 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 700, margin: "0.5rem 0rem" }}
            >
              Are you a professional in the pet industry? Choose the appropriate
              option
            </Typography>
            <ProfessionCheckBox setProfession={setProfession} />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 8 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 700, margin: "0.5rem 0rem" }}
            >
              If you are in pet industry, What's the name of your business?
            </Typography>

            <TextField
              required
              fullWidth
              name="If you are in pet industry, What's the name of your business?"
              value={businessName}
              onChange={handleBusinessName}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 8 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 700, margin: "0.5rem 0rem" }}
            >
              If you are a professional in the pet industry, how many years of
              experience do you have?
            </Typography>

            <TextField
              required
              fullWidth
              type="number"
              name="If you are a professional in the pet industry, how many years of experience do you have?"
              value={experience}
              onChange={handleExperience}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 8 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 700, margin: "0.5rem 0rem" }}
            >
              How did you find your way to this course?
            </Typography>
            <TextField
              required
              fullWidth
              name="How did you find your way to this course?"
              value={source}
              onChange={handleSource}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 8 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 700, margin: "0.5rem 0rem" }}
            >
              What is your vision in joining this course? How would you describe
              yourself?
            </Typography>
            <TextField
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={vision}
              onChange={handleVision}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 8 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 700, margin: "0.5rem 0rem" }}
            >
              What's your T-shirt size?
            </Typography>
            <TShirtSizeRadioButton setSize={setSize} />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 8 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 700, margin: "0.5rem 0rem" }}
            >
              Your instagram handle
            </Typography>
            <TextField
              required
              fullWidth
              name="Mention link to your instagram handle"
              value={instagramHandle}
              onChange={handleInstagramHandle}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 8 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 700, margin: "0.5rem 0rem" }}
            >
              Do you have any medical conditions that we need to be aware of?
              (Like asthma or ligament tears or heart conditions)
            </Typography>

            <TextField
              required
              fullWidth
              name="Do you have any medical conditions that we need to be aware of? (Like asthma or ligament tears or heart conditions) "
              value={medical}
              onChange={handleMedical}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 8 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 700, margin: "0.5rem 0rem" }}
            >
              Will you bring your dog along for this course?
            </Typography>

            <BringDogRadioButton setBringingDog={setBringingDog} />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 8 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 700, margin: "0.5rem 0rem" }}
            >
              Which City are you from?
            </Typography>

            <TextField
              required
              fullWidth
              id="city"
              name="city"
              autoComplete="city"
              value={city}
              onChange={handleCity}
            />
          </Grid>
          <div style={{ width: "50%", margin: "auto" }}>
            {console.log("error",error)}
            {error &&<div style={{ marginTop: "2rem" }}>
              <Alert variant="filled" severity="error">
                Please fill all the details
              </Alert>
            </div>}
            
            <div style={{ width: "fit-content", margin: "auto" }}>
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleProceedPayment}
                style={{backgroundColor:"#59150a"}}
              >
                Proceed to Payment
              </Button>
            </div>
          </div>
        </Grid>
      </div>
    </>
  );
};

export default RegistrationForm;
