import { Box, Button, Checkbox, FormControlLabel, Grid, Link, TextField, Typography } from '@mui/material'


import { useState } from 'react';
import axios from 'axios'
import CourseDetails from "./assets/TTT-July2024.pdf"
import SuccessModal from "./SuccessModal"
import FailureModal from "./FailureModal"
import Loader from "./Loader"
const RegistrationForm =()=>{
  const [name,setName]=useState("")
  const[email,setEmail]=useState("")
  const[number,setNumber]=useState("")
  const[city,setCity]=useState("")
  const [date,setDate]=useState()
  const [success, setSuccess]= useState(false)
  const [failure,setFailure]= useState(false)
  const[loader,setLoader]=useState(false)
  const handleName=(e)=>{
    setName(e.target.value)
    console.log(name)
  }
  const handleEmail=(e)=>{
    setEmail(e.target.value)
  }
  const handleNumber=(e)=>{
    setNumber(e.target.value)
  }
  const handleCity=(e)=>{
setCity(e.target.value)
  }
  const handleDate=(e)=>{
    setDate(e.target.value)
  }
  
const handleRegisterNow=()=>{
  const HostData = JSON.stringify({
    subject: "TTT July 2024 Registration",
    to: "Kumar@canaanpetresort.com",
    htmlParts: [
      `<table style='width: 100%; border: 1px solid #ccc;'>`, // Starting the table with a simple border for clarity
        `<tr>`, // Row for the name
          `<td><strong>Name:</strong></td>`,
          `<td>${name}</td>`,
        `</tr>`,
        `<tr>`, // Row for the email
          `<td><strong>Email:</strong></td>`,
          `<td>${email}</td>`,
        `</tr>`,
        `<tr>`, // Row for the phone number
          `<td><strong>Number:</strong></td>`,
          `<td>${number}</td>`,
        `</tr>`,
        `<tr>`, // Row for the city
          `<td><strong>City:</strong></td>`,
          `<td>${city}</td>`,
        `</tr>`,
       
      `</table>`,
    ]
  });
  const CordinatorData = JSON.stringify({
    subject: "TTT July 2024 Registration",
    to: "sagarika.katikam@gmail.com",
    htmlParts : [
      `<table style='width: 100%; border: 1px solid #ccc;'>`, // Starting the table with a simple border for clarity
        `<tr>`, // Row for the name
          `<td><strong>Name:</strong></td>`,
          `<td>${name}</td>`,
        `</tr>`,
        `<tr>`, // Row for the email
          `<td><strong>Email:</strong></td>`,
          `<td>${email}</td>`,
        `</tr>`,
        `<tr>`, // Row for the phone number
          `<td><strong>Number:</strong></td>`,
          `<td>${number}</td>`,
        `</tr>`,
        `<tr>`, // Row for the city
          `<td><strong>City:</strong></td>`,
          `<td>${city}</td>`,
        `</tr>`,
       
      `</table>`,
    ],
  });
  const ParticipantData = JSON.stringify({
    subject: "Canaan TTT course Registration confirmation",
    to: email,
    htmlParts: [
      `<h1>Hi ${name}!</h1>`,
      `<h2>We have received your registration.</h2>`,
      `<h2>Our Team will get back to you at the earliest.</h2>`,
      `<h2>Meanwhile, Please find the attached course details document and have your questions ready.</h2>`,
      `<h2>Happy Training :-)</h2>`,
      `<h4>Best Regards,</h4>`,
      `<h4>Kumar Rich</h4>`
    ],
  });
  setLoader(true)
  sendEmail(HostData)
  sendEmail(ParticipantData)
  sendEmail(CordinatorData)
  sendApplicationEmail()
}

  const sendEmail = (data) => {
  const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://hidden-atoll-09446-84ebc53750bc.herokuapp.com/sendEmail',
      headers: { 
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
  
      .then(response => {
        setLoader(false)
        setSuccess(true)
      
      })
      .catch(error => {
        setLoader(false)
        setFailure(true)
        console.error('Error sending email:', error);
      });
  };

  const sendApplicationEmail=()=>{
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://hidden-atoll-09446-84ebc53750bc.herokuapp.com/sendBatchEmails',
      headers: { 
        'Content-Type': 'application/json'
      },
      
      data: {
        "emailAddresses": [
           email
        ]
    }
    };
    axios.request(config)
  
    .then(response => {
console.log("success")
    })
    .catch(error => {
      console.error('Error sending email:', error);
    });
  }

  return(
    <>
   {loader &&<Loader/>}
    {success && 
    <SuccessModal/>
  }
  {failure && <FailureModal/> }
  
    <div style={{display:"flex", alignItems:"center", height:"100%", margin:"auto"}}>
   
   <Box component="form" noValidate  sx={{ mt: 3 }} style={{margin:"auto"}}>
     
     <div>
     
  <Typography style={{display:"flex", justifyContent:"center", fontSize:45, fontWeight:900,color:"black"}}>Register Now!
        </Typography>
      <Typography  style={{display:"flex", justifyContent:"center", fontSize:20, fontWeight:600,color:"black"}}>Get Course Deatils Instantly</Typography>
  
      </div>
    <Grid container spacing={2} style={{marginTop:"2rem"}}>
      
      <Grid item xs={12} sm={12}>
        <TextField
          required
          fullWidth
          id="Name"
          label="Name"
          name="Name"
          autoComplete="name"
          style={{marginBottom:"1px white"}}
          value={name}
          onChange={handleName}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={email}
          onChange={handleEmail}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          required
          fullWidth
          name="MobileNumber"
          label="Contact Number"
          type="number"
          id="number"
          autoComplete="number"
          value={number}
          onChange={handleNumber}
        />
        
      </Grid>
         <Grid item xs={12} sm={12} md={12}> 
          <TextField
          required
          fullWidth
          id="city"
          label="city"
          name="city"
          autoComplete="city"
          value={city}
          onChange={handleCity}
        /></Grid>
        
  
    </Grid>
    <Button
      fullWidth
      variant="contained"
      sx={{ mt: 3, mb: 2 }}
      onClick={handleRegisterNow}
      style={{backgroundColor:"#59150a"}}
    >
   Register Now!
    </Button>
    
  </Box>
  
  
    </div>
    </>

 
)    
}

export default RegistrationForm