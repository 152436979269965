import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormLabel } from '@mui/material';

export default function ProfessionCheckBox(props) {
  const[profession,setProfession]=React.useState([])
  const handleProfession=(e)=>{
    setProfession([...profession, e.target.value])
  }
  return (<>
   <FormGroup onChange={handleProfession}>
      <FormControlLabel control={<Checkbox value="Boarder" />} label="Boarder" />
      <FormControlLabel control={<Checkbox value="Trainer" />} label="Trainer" />

      <FormControlLabel control={<Checkbox value="Pet Parent" />} label="Pet Parent" />

      <FormControlLabel control={<Checkbox value="Pet srore Owner"  />} label="Pet srore Owner" />

      <FormControlLabel control={<Checkbox value="Groomer" />} label="Groomer" />

      <FormControlLabel control={<Checkbox value="Breeder" />} label="Breeder" />

      <FormControlLabel control={<Checkbox value="Show Handler"  />} label="Show Handler" />

      <FormControlLabel control={<Checkbox value="Decoy/ Helper" />} label="Decoy/ Helper" />
      <FormControlLabel control={<Checkbox value="Other" />} label="Other" />


    </FormGroup>
   </>
   
  );
}